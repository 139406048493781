export interface HeaderData {
    year?: number;
    dataType: number;
    abp: IAbp;
    gu: IGu;
    finalAbp?: number;
    regional?: RegionalData;
}

export interface RegionalData {
    abp: IAbp;
    gu: IGu;
    dataType: number;
}

export interface DataType {
    id: number;
    code: string;
    name_ru: string;
    name_kk: string;
    beg_date: any;
    end_date: any;
    name: string;
}

export interface IGr {
    id: number;
    gr: number;
    pgr?: number;
    abp?: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: string;
    short_name_kk: string;
    beg_date: string;
    end_date: any;
    full_code: string;
    budget_level_id?: number;
    name: string;
}

export interface IPgr {
    idArr: number[];
    id: number;
    gr: number;
    pgr: number;
    abp?: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: any;
    short_name_kk: any;
    beg_date: any;
    end_date: any;
    full_code: string;
    budget_level_id: number;
    name: string;
}

export interface IAbp {
    idArr: number[];
    id: number;
    gr: number;
    pgr: number;
    abp: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: any;
    short_name_kk: any;
    beg_date: any;
    end_date: any;
    full_code: string;
    budget_level_id: number;
    name: string;
}

/** DEPRECATED
 * export interface Prg {
 *     idArr: number[];
 *     id: number;
 *     gr: number;
 *     pgr: number;
 *     abp: number;
 *     prg: number;
 *     ppr?: number;
 *     type: number;
 *     name_ru: string;
 *     name_kk: string;
 *     short_name_ru: any;
 *     short_name_kk: any;
 *     beg_date: any;
 *     end_date: any;
 *     full_code: string;
 *     budget_level_id?: number;
 *     name: string;
 *     develop_type?: number;
 * }
 */

/** DEPRECATED
 * export interface Ppr {
 *     idArr: number[];
 *     id: number;
 *     gr: number;
 *     pgr: number;
 *     abp: number;
 *     prg: number;
 *     ppr: number;
 *     type: number;
 *     name_ru: string;
 *     name_kk: string;
 *     short_name_ru: any;
 *     short_name_kk: any;
 *     beg_date: any;
 *     end_date: any;
 *     full_code: string;
 *     budget_level_id?: number;
 *     name: string;
 * }
 */

export interface IPrg {
    gr: number;
    pgr: number;
    abp: number;
    prg: number;
    ppr?: number;
    pprList?: IPpr[];
    type: number;
    develop_type?: number;
    name_ru: string;
    name_kk: string;
    text: string
    budget_level_id?: number[];
}

export interface IPpr {
    gr: number;
    pgr: number;
    abp: number;
    prg: number;
    ppr: number;
    type: number;
    develop_type?: number;
    name_ru: string;
    name_kk: string;
    budget_level_id?: number[] | number;
    source?: string;
}

export interface IGu {
    id: number;
    code: string;
    source_id: string;
    summary_flag: string;
    name_ru: string;
    name_kk: string;
    gu_bin: string;
    gu_rnn?: string;
    id_region: number;
    id_budget_type: string;
    address: string;
    beg_date: string;
    end_date: string;
    kato: string;
    budget_type: string;
    rucov: string; // Руководитель
    buhgal: string; // Бухгалтер
    abp_owner: string;
    name: string;
}

export interface IBipProjectData {
    /** Код БИПа */
    code: string; // Код

    /** Наименование на русском */
    nameRu: string; // Рус.наименование

    /** Наименование на казахском */
    nameKk: string; // Каз. наименование

    /** Заголовок проекта с информацией о ГУ */
    header: {
        abp: number;
        dataType: number;
        finalAbp: number;
        gu: IGu;
        regional?: {
            abp: number;
            dataType: number;
            gu: IGu;
        };
        year: number;
    };

    /** Тип объекта */
    object: string;

    /** Тип проекта */
    projectType: string;

    /**  Гос. программы */
    governmentProgram: IGovProgram[];

    /** Регион ГУ */
    region_budget: string;

    /** Регион конечного ГУ */
    district_budget: string;

    /** Код като района */
    region: string;

    /** Объект населенного пункта по като */
    localityObject: ILocalityObject | null;

    /** Экономическая внутренняя норма доходности (EIRR) */
    directCash: number;

    /** Сумма запроса ЦТ из РБ, тыс. тенге */
    considerationGA: number;

    /** Общая стоимость */
    totalCost: number;

    /** Стоимость без ПИР и ГЭ */
    costWithout: number;

    /** прямые денежные притоки */
    isDirectCash: boolean;

    /** Направлено на рассмотрение в ЦГО */
    isConsiderationCentralGovernment: boolean; //

    /** Период */
    period: ProjectPeriod; //

    /** modellingData */
    modellingData: ModellingData;

    /** перечень прикрепленных файлов */
    files: any[]; //

    /** Код инициатора */
    variant: string;

    /** Код получателя инвестиций */
    variant_recipient: string;

    /** check */
    check: boolean;
}

export interface ILocalityObject {
    id: number,
    code: string,
    par_id: number,
    name_kz: string,
    name_ru: string,
    date_end: string | null,
    date_begin: string,
    type_of_settlement: string
}

export interface IGovProgram {
    /** id программы */
    id: number;

    /** kato_stat_id программы */
    kato_stat_id: number;

    /** parent_id программы */
    parent_id: number | null;

    /** Код программы */
    code: string;

    /** Код */
    code_kato: string;

    /** Описание */
    description: string;

    /** Дата создания */
    create_date: string;

    /** Дата начала */
    start_date: string;

    /** Дата обновления */
    update_date: string;

    /** Дата конца */
    end_date: string;

    /** Модуль */
    module: string;

    /** Наименование на английском */
    name_en: string;

    /** Наименование на казахском */
    name_kz: string;

    /** Наименование на русском */
    name_ru: string;
}

export interface BipDict {
    id: number;
    code?: string;
    nameRu: string;
    nameKk?: string;
}

export interface IFallbackFindItemResponse {
    code: null;
    name_ru: string;
    name_kz: string;
    name_en: string;
}

export type ProjectType = BipDict;

export type ProjectStatus = BipDict;

export const ProjectStatusList: ProjectStatus[] = [
    {id: 1, code: '1', nameRu: 'Заявка'},
    {id: 2, code: '2', nameRu: 'На рассмотрении'},
    {id: 3, code: '3', nameRu: 'Рассмотрено УЭБП. Поддержано'},
    {id: 4, code: '4', nameRu: 'Рассмотрено УЭБП. Отказано'},
    {id: 5, code: '5', nameRu: 'Рассмотрено ОБК. Поддержано'},
    {id: 6, code: '6', nameRu: 'Рассмотрено ОБК. Отказано'},
    {id: 7, code: '7', nameRu: 'Реализуется'}
];

export const ProjectRealizingList: ProjectRealizing[] = [
    {
        id: 1,
        code: '1',
        nameRu: 'Развитие объектов образования',
        nameKk: 'Білім беру объектілерін дамыту'
    },
    {
        id: 2,
        code: '2',
        nameRu: 'Развитие объектов здравоохранения',
        nameKk: 'Денсаулық сақтау объектілерін дамыту'
    },
    {
        id: 3,
        code: '3',
        nameRu: 'Развитие социальных объектов',
        nameKk: 'Әлеуметтік нысандардың дамуы'
    },
    {
        id: 4,
        code: '4',
        nameRu: 'Развитие объектов спорта',
        nameKk: 'Спорт ғимараттарын дамыту'
    },
    {
        id: 5,
        code: '5',
        nameRu: 'Развитие объектов культуры',
        nameKk: 'Мәдени объектілерді дамыту'
    },
    {
        id: 6,
        code: '6',
        nameRu: 'Развитие теплоэнергетической системы',
        nameKk: 'Жылу -энергетикалық жүйенің дамуы'
    },
    {
        id: 7,
        code: '7',
        nameRu: 'Развитие системы водообеспечения в городах',
        nameKk: 'Қалаларда сумен жабдықтау жүйесін дамыту'
    },
    {
        id: 8,
        code: '8',
        nameRu: 'Развитие системы водообеспечения в сельских населенных пунктах',
        nameKk: 'Ауылдық жерлерде сумен жабдықтау жүйесін дамыту'
    },
    {
        id: 9,
        code: '9',
        nameRu: 'Жилищное строительство: жилье',
        nameKk: 'Тұрғын үй құрылысы: тұрғын үй'
    },
    {
        id: 10,
        code: '10',
        nameRu: 'Жилищное строительство: Инженерно-коммуникационные сети к объектам жилищного строительства',
        nameKk: 'Тұрғын үй құрылысы: тұрғын үй құрылысы объектілеріне инженерлік -коммуникациялық желілер'
    },
    {
        id: 11,
        code: '11',
        nameRu: 'Реализация мероприятий в рамках Программы «Дорожная карта бизнеса 2025»',
        nameKk: '«Бизнестің жол картасы 2025» Бағдарламасы аясындағы іс -шараларды жүзеге асыру'
    },
    {
        id: 12,
        code: '12',
        nameRu: 'Реализация мероприятий в моногородах',
        nameKk: 'Моноқалалардағы оқиғаларды жүзеге асыру'
    },
    {
        id: 13,
        code: '13',
        nameRu: 'Развитие инженерной инфраструктуры в рамках Программы развития регионов до 2025 года',
        nameKk: 'Өңірлерді дамытудың 2025 жылға дейінгі бағдарламасы аясында инженерлік инфрақұрылымды дамыту'
    },
    {
        id: 14,
        code: '14',
        nameRu: 'Охрана окружающей среды',
        nameKk: 'Қоршаған ортаны қорғау'
    },
    {
        id: 15,
        code: '15',
        nameRu: 'Развитие инфраструктуры специальных экономических зон, индустриальных зон, индустриальных парков',
        nameKk: 'Арнайы экономикалық аймақтардың, индустриалды аймақтардың, индустриалды парктердің инфрақұрылымын дамыту'
    },
    {
        id: 16,
        code: '16',
        nameRu: 'Развитие объектов мобилизационной подготовки и чрезвычайных ситуаций',
        nameKk: 'Жұмылдыру дайындығы мен төтенше жағдайлардың құралдарын дамыту'
    },
    {
        id: 17,
        code: '17',
        nameRu: 'Развитие коммунального хозяйства',
        nameKk: 'Коммуналдық шаруашылықты дамыту'
    },
    {
        id: 18,
        code: '18',
        nameRu: 'Орошение',
        nameKk: 'Суару'
    },
    {
        id: 19,
        code: '19',
        nameRu: 'Развитие транспортной инфраструктуры',
        nameKk: 'Көлік инфрақұрылымын дамыту'
    },
    {
        id: 20,
        code: '20',
        nameRu: 'Развитие социальной и инженерной инфраструктуры в сельских населенных пунктах в рамках проекта "Ауыл-Ел бесігі"',
        nameKk: '«Ауыл-Ел бесігі» жобасы аясында ауылдық елді мекендердің әлеуметтік және инженерлік инфрақұрылымын дамыту'
    },
    {
        id: 21,
        code: '21',
        nameRu: 'Увеличение уставного капитала',
        nameKk: 'Жарғылық капиталды ұлғайту'
    }
];

export const ProjectTypeList: ProjectType[]
    = [
    {
        'id': 1,
        'code': '1',
        'nameRu': 'Разработка ПСД (Строительство)',
        'nameKk': 'Жобалық -сметалық құжаттаманы әзірлеу (Құрылыс)'
    },
    {
        'id': 2,
        'code': '2',
        'nameRu': 'Разработка ПСД (Реконструкция)',
        'nameKk': 'Жобалық -сметалық құжаттаманы әзірлеу (Қайта құру)'
    },
    {
        'id': 3,
        'code': '3',
        'nameRu': 'Строительство',
        'nameKk': 'Құрылыс'
    },
    {
        'id': 4,
        'code': '4',
        'nameRu': 'Реконструкция',
        'nameKk': 'Қайта құру'
    },
    {
        'id': 5,
        'code': '5',
        'nameRu': 'Уставной капитал',
        'nameKk': 'Жарғылық капиталы'
    },
    {
        'id': 6,
        'code': '6',
        'nameRu': 'Информатизация',
        'nameKk': 'Ақпараттандыру'
    },
    {
        'id': 7,
        'code': '7',
        'nameRu': 'Капитальный ремонт (по дорогам местного значения)',
        'nameKk': 'Күрделі жөндеу (жергілікті маңызы бар жолдар)'
    },
    {
        'id': 8,
        'code': '8',
        'nameRu': 'Средний ремонт (по дорогам местного значения)',
        'nameKk': 'Орташа жөндеу (жергілікті маңызы бар жолдар)'
    }
];

export interface BipPowerDict extends BipDict {
    objectType: number;
}

export const ProjectPowerList: BipPowerDict[]
    = [
    {
        'id': 1,
        'code': '1',
        'objectType': 1,
        'nameRu': 'Количество ученических мест',
        'nameKk': ''
    },
    {
        'id': 2,
        'code': '2',
        'objectType': 1,
        'nameRu': 'Площадь',
        'nameKk': ''
    },
    {
        'id': 3,
        'code': '3',
        'objectType': 2,
        'nameRu': 'Количество ученических мест',
        'nameKk': ''
    },
    {
        'id': 4,
        'code': '3',
        'objectType': 2,
        'nameRu': 'Площадь',
        'nameKk': ''
    },
    {
        'id': 5,
        'code': '5',
        'objectType': 24,
        'nameRu': 'Протяженность',
        'nameKk': ''
    },
    {
        'id': 6,
        'code': '6',
        'objectType': 19,
        'nameRu': 'Протяженность сетей',
        'nameKk': ''
    },
    {
        'id': 7,
        'code': '7',
        'objectType': 19,
        'nameRu': 'Мощность',
        'nameKk': ''
    },
    {
        'id': 8,
        'code': '8',
        'objectType': 19,
        'nameRu': 'Напряжение',
        'nameKk': ''
    },
    {
        'id': 9,
        'code': '9',
        'objectType': 19,
        'nameRu': 'Площадь',
        'nameKk': ''
    }
];

export interface BipObjectDict extends BipDict {
    direction: number [];
}

export const ProjectObjectList: BipObjectDict[]
    = [
    {
        'id': 1,
        'code': '1',
        'nameRu': 'Общеобразовательная школа',
        'nameKk': 'Жалпы білім беретін мектеп',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 2,
        'code': '2',
        'nameRu': 'Детский сад',
        'nameKk': 'Балабақша',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 3,
        'code': '3',
        'nameRu': 'Общежития при учебных заведениях',
        'nameKk': 'Білім беру ұйымдарындағы жатақханалар',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 4,
        'code': '4',
        'nameRu': 'Многопрофильная больница',
        'nameKk': 'Көпбейінді аурухана',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 5,
        'code': '5',
        'nameRu': 'Перинатальный центр',
        'nameKk': 'Перинаталдық орталық',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 6,
        'code': '6',
        'nameRu': 'Врачебная амбулатория',
        'nameKk': 'Дәрігерлік амбулатория',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 7,
        'code': '7',
        'nameRu': 'Фельдшерско-акушерский пункт',
        'nameKk': 'Фельдшерлік-акушерлік станция',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 8,
        'code': '8',
        'nameRu': 'Медицинский пункт',
        'nameKk': 'Медициналық пункт',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 9,
        'code': '9',
        'nameRu': 'Медицинский центр',
        'nameKk': 'Медициналық орталық',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 10,
        'code': '10',
        'nameRu': 'Спортивное сооружение',
        'nameKk': 'Спорттық құрылыс',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 11,
        'code': '11',
        'nameRu': 'ИКИ для спортивных сооружений',
        'nameKk': 'Спорттық құрылысына ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 12,
        'code': '12',
        'nameRu': 'Сооружение культуры',
        'nameKk': 'Мәдениет нысанының құрылысы',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 13,
        'code': '13',
        'nameRu': 'Система теплоснабжения',
        'nameKk': 'Жылу жүйесі',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 14,
        'code': '14',
        'nameRu': 'Котельная',
        'nameKk': 'Қазандық',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 15,
        'code': '15',
        'nameRu': 'Система водоснабжения',
        'nameKk': 'Сумен жабдықтау жүйесі',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 16,
        'code': '16',
        'nameRu': 'Система водоотведения',
        'nameKk': 'Cу тарту жүйесі',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 17,
        'code': '17',
        'nameRu': 'Многоквартирный жилой дом (МЖД)',
        'nameKk': 'Көппәтерлі тұрғын үй',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 18,
        'code': '18',
        'nameRu': 'Индивидуальное жилищное строительство (ИЖС)',
        'nameKk': 'Жеке тұрғын үй',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 19,
        'code': '19',
        'nameRu': 'ИКИ для МЖД',
        'nameKk': 'Көппәтерлі тұрғын үй ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 20,
        'code': '20',
        'nameRu': 'ИКИ для ИЖС',
        'nameKk': 'Жеке тұрғын үй ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 21,
        'code': '21',
        'nameRu': 'Объект охраны окружающей среды',
        'nameKk': 'Қоршаған ортаны қорғау объекті',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 22,
        'code': '22',
        'nameRu': 'Объект по сбору, переработке и утилизации ТБО',
        'nameKk': 'Қатты тұрмыстық қалдықтарды жинау, өңдеу және кәдеге жарату объекті',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 23,
        'code': '23',
        'nameRu': 'ИКИ для СЭЗ',
        'nameKk': 'Арнаулы экономикалық аймақ ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 24,
        'code': '24',
        'nameRu': 'ИКИ для объектов промышленности',
        'nameKk': 'Өнеркәсіп объектілерінің ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 25,
        'code': '25',
        'nameRu': 'Система противодействия ЧС',
        'nameKk': 'Төтенше жағдайларды жою жүйесі',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 26,
        'code': '26',
        'nameRu': 'ИКИ для объектов сельского хозяйства',
        'nameKk': 'Ауыл шаруашылығы объектілерінің ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 27,
        'code': '27',
        'nameRu': 'Автомобильная дорога внутри НП',
        'nameKk': 'Автомобиль жолдары',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 28,
        'code': '28',
        'nameRu': 'Благоустройство улицы',
        'nameKk': 'Көшені көріктендіру',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 29,
        'code': '29',
        'nameRu': 'Уставной капитал',
        'nameKk': 'Жарғылық капиталы',
        'direction': [5]
    },
    {
        'id': 30,
        'code': '30',
        'nameRu': 'Система электроснабжения',
        'nameKk': 'Электр жүйелері',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 31,
        'code': '31',
        'nameRu': 'Административное здание',
        'nameKk': 'Әкімшілік ғимараты',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 32,
        'code': '32',
        'nameRu': 'ИКИ для прочих объектов МСП в рамках Госпрограмм',
        'nameKk': 'Мемлекеттік бағдарламалар бойынша басқа ШОБ үшін ИКИ',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 33,
        'code': '33',
        'nameRu': 'Пожарное депо',
        'nameKk': 'Өрт сөндіру депосы',
        'direction': [1, 2, 3, 4]
    },
    {
        'id': 34,
        'code': '34',
        'nameRu': 'Информационая система',
        'nameKk': 'Информациялық жүйе',
        'direction': [6]
    },
    {
        'id': 35,
        'code': '35',
        'nameRu': 'Капитальный ремонт дороги областного значения',
        'nameKk': 'Облыстық маңызы бар жолдың күрделі жөндеуі',
        'direction': [7]
    },
    {
        'id': 36,
        'code': '36',
        'nameRu': 'Капитальный ремонт дороги районного значения',
        'nameKk': 'Аймақтық маңызы бар жолдың күрделі жөндеуі',
        'direction': [7]
    },
    {
        'id': 37,
        'code': '37',
        'nameRu': 'Средний ремонт дороги областного значения',
        'nameKk': 'Облыстық маңызы бар жолдың орташа жөндеуі',
        'direction': [8]
    },
    {
        'id': 38,
        'code': '38',
        'nameRu': 'Средний ремонт дороги районного значения',
        'nameKk': 'Аймақтық маңызы бар жолдың орташа жөндеуі',
        'direction': [8]
    }
];

export interface CapacityIndicatorsFacilities extends BipDict {
    code: string;
    objectCode: number[];
    unit: string;
    data_type: string;
    foreign_data?: boolean;
    source_type: string;
    calc_type: string;
    criteria_type?: string;
}

export const CapacityIndicatorsFacilitiesList: CapacityIndicatorsFacilities[]
    = [
    {
        'id': 1,
        'code': '1',
        'objectCode': [
            1
        ],
        'nameRu': 'Текущий дефицит ученических мест в близлежащей школе',
        'unit': 'детей',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 2,
        'code': '2',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Количество созданных рабочих мест',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 3,
        'code': '2.1.',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            17,
            18,
            21,
            22,
            23,
            24,
            25,
            29,
            33
        ],
        'nameRu': 'Количество созданных рабочих мест, постоянные',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 4,
        'code': '2.2.',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            32,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Количество созданных рабочих мест, временные',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 5,
        'code': '3',
        'objectCode': [
            2
        ],
        'nameRu': 'Дефицит мест в близлежащем детском саду',
        'unit': 'детей',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 6,
        'code': '4',
        'objectCode': [
            3
        ],
        'nameRu': 'Дефицит мест в ТИПО',
        'unit': 'студентов',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 7,
        'code': '5',
        'objectCode': [
            3,
            4,
            5,
            6,
            9,
            13,
            14,
            15,
            16,
            21,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Охват населения в рамках реализации проекта',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 8,
        'code': '6',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Общественный резонанс: СМИ, забастовки, жалобы в госорганы, отчет акима перед населением',
        'unit': '',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 9,
        'code': '7',
        'objectCode': [
            3,
            6,
            9,
            11,
            12,
            13,
            14,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Численность населения НП',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 10,
        'code': '8',
        'objectCode': [
            4,
            6,
            9
        ],
        'nameRu': 'Стандартизованный коэффициент смертности, текущий',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 11,
        'code': '9',
        'objectCode': [
            5
        ],
        'nameRu': 'Младенческая смертность, количество случаев на 1000 родившихся живыми, текущий',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 12,
        'code': '10',
        'objectCode': [
            13,
            14,
            30
        ],
        'nameRu': 'Потенциальные потребители: школа, детский сад, больница',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 13,
        'code': '11',
        'objectCode': [
            17,
            18,
            19,
            20
        ],
        'nameRu': 'Обеспеченность жильем на одного проживающего, текущая',
        'unit': 'квадратные метры',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 14,
        'code': '12',
        'objectCode': [
            17,
            19
        ],
        'nameRu': 'Строительство многоквартирных жилых домов',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 15,
        'code': '13',
        'objectCode': [
            17,
            19
        ],
        'nameRu': 'Количество человек в очереди на получение жилья',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 16,
        'code': '14',
        'objectCode': [
            18,
            20
        ],
        'nameRu': 'Количество человек в очереди на получение земельного участка',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 17,
        'code': '15',
        'objectCode': [
            22
        ],
        'nameRu': 'Доля переработки и утилизации ТБО к их образованию',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 18,
        'code': '16',
        'objectCode': [
            22
        ],
        'nameRu': 'Объем отходов, поступивших на захоронение в НП в год',
        'unit': 'тыс. тонн',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 19,
        'code': '17',
        'objectCode': [
            22
        ],
        'nameRu': 'Запас оставшейся мощности существующих ТБО',
        'unit': 'тыс. тонн',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 20,
        'code': '18',
        'objectCode': [
            24,
            26,
            27
        ],
        'nameRu': 'Кол-во хоз-субъектов',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 21,
        'code': '19',
        'objectCode': [
            25,
            33
        ],
        'nameRu': 'Уровень обеспеченности инфраструктурой противодействия чрезвычайным ситуациям, текущий',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 22,
        'code': '20',
        'objectCode': [
            27
        ],
        'nameRu': 'Площадь орошаемого участка в рамках реализации проекта',
        'unit': 'гектар',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': 'Потребность'
    },
    {
        'id': 23,
        'code': '21',
        'objectCode': [
            27,
            28,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Интенсивность автомобилей в сутки (среднегодовая)',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 24,
        'code': '22',
        'objectCode': [
            27,
            28
        ],
        'nameRu': 'Численность населения вдоль участка дороги (в пределах 5 км)',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 25,
        'code': '23',
        'objectCode': [
            29
        ],
        'nameRu': 'Софинансирование проекта из собственных средств получателя',
        'unit': 'млн тенге',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 26,
        'code': '24',
        'objectCode': [
            29
        ],
        'nameRu': 'Социальная направленность проекта',
        'unit': '',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 27,
        'code': '25',
        'objectCode': [
            24,
            29
        ],
        'nameRu': 'ENPV',
        'unit': 'млн тенге',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 28,
        'code': '26',
        'objectCode': [
            29
        ],
        'nameRu': 'Соответствие стратегических документам: Наличие индикаторов в ПРО, в Госпрограммах; Наличие Поручений Президента, Премьер Министра, Акима области',
        'unit': '',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 29,
        'code': '27',
        'objectCode': [
            29
        ],
        'nameRu': 'Доля государства в УК',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 30,
        'code': '28',
        'objectCode': [
            30
        ],
        'nameRu': 'Текущий % оснащения сетями электроснабжения',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 31,
        'code': '29',
        'objectCode': [
            31
        ],
        'nameRu': 'Наличие административного здания',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 32,
        'code': '30',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            23,
            24,
            25,
            26,
            27,
            30,
            31,
            33
        ],
        'nameRu': 'Текущий износ объекта',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 33,
        'code': '31',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, текущая',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 34,
        'code': '32',
        'objectCode': [
            27,
            28,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Наличие крупных объектов вдоль учаска дороги: промышленные предприятия, сельхоз предприятия, туристические объекты',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 35,
        'code': '33',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Дата последнего ремонта',
        'unit': 'дата',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 36,
        'code': '34',
        'objectCode': [
            33
        ],
        'nameRu': 'Наличиe пожарного депо',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 37,
        'code': '35',
        'objectCode': [
            13
        ],
        'nameRu': 'Текущий % оснащения сетями теплоснабжения',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 38,
        'code': '36',
        'objectCode': [
            23
        ],
        'nameRu': 'Текущий % оснащения ИКИ для СЭЗ',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 39,
        'code': '37',
        'objectCode': [
            23
        ],
        'nameRu': 'Кол-во потенциальных инвесторов',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 40,
        'code': '38',
        'objectCode': [
            24
        ],
        'nameRu': 'ИКИ для объектов обрабатывающей промышленности',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 41,
        'code': '39',
        'objectCode': [
            1,
            13,
            15,
            16
        ],
        'nameRu': 'Наличие аварий за последний год',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 42,
        'code': '40',
        'objectCode': [
            1
        ],
        'nameRu': 'Использование мощности объекта',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 43,
        'code': '41',
        'objectCode': [
            1,
            10,
            12,
            13,
            15,
            16,
            17,
            19,
            23,
            24,
            35,
            36,
            37,
            38
        ],
        'nameRu': '% МБ от финансирования 2022',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 44,
        'code': '42',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Протяженность ремонтируемых участков',
        'unit': 'километр',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 45,
        'code': '43',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Стоимость за 1 км',
        'unit': 'млн тенге',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 46,
        'code': '44',
        'objectCode': [
            1,
            10,
            12,
            13,
            15,
            16,
            17,
            19,
            23,
            24,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Накопленная инфляция',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 47,
        'code': '45',
        'objectCode': [
            1,
            10,
            12,
            13,
            15,
            16,
            17,
            19,
            23,
            24,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Поправочный коэффициент',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 48,
        'code': '46',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии**',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 49,
        'code': '47',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Проведение ремонтных работ',
        'unit': 'километр',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 50,
        'code': '48',
        'objectCode': [
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Численность Населения НП**',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 51,
        'code': '49',
        'objectCode': [
            17
        ],
        'nameRu': 'Общая площадь квартир',
        'unit': 'квадратные метры',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 52,
        'code': '50',
        'objectCode': [
            1,
            10,
            12,
            17
        ],
        'nameRu': 'Стоимость за 1 кв м',
        'unit': 'тыс. тенге',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 53,
        'code': '51',
        'objectCode': [
            17,
            19
        ],
        'nameRu': 'Обеспеченность жильем на одного проживающего**',
        'unit': 'квадратные метры',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 54,
        'code': '52',
        'objectCode': [
            1,
            13,
            17
        ],
        'nameRu': 'Уровень удовлетворенности качеством работы МИО',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 55,
        'code': '53',
        'objectCode': [
            17,
            19
        ],
        'nameRu': 'Доступность жилья, отношение среднедушевых доходов населения на среднюю стоимость 1 кв. м. (продажа нового жилья (квартиры в много квартирных домах))',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 56,
        'code': '54',
        'objectCode': [
            17,
            19
        ],
        'nameRu': 'Динамика роста населения НП за последние 5 лет',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 57,
        'code': '55',
        'objectCode': [
            13
        ],
        'nameRu': 'Протяженность тепловой сети',
        'unit': 'километр',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 58,
        'code': '56',
        'objectCode': [
            13
        ],
        'nameRu': 'Протяженность реконструируемого объекта',
        'unit': 'километр',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 59,
        'code': '57',
        'objectCode': [
            12,
            13
        ],
        'nameRu': 'Нет прямого ЦИ',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 60,
        'code': '58',
        'objectCode': [
            23
        ],
        'nameRu': 'Объем производства на территории СЭЗ «Павлодар», млрд. тенге (специфичный)',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 61,
        'code': '59',
        'objectCode': [
            23
        ],
        'nameRu': 'Количество участников СЭЗ «Павлодар», (специфичный)',
        'unit': 'единица',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 62,
        'code': '60',
        'objectCode': [
            24
        ],
        'nameRu': 'Рост производительности труда в обрабатывающей промышленности',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 63,
        'code': '61',
        'objectCode': [
            24
        ],
        'nameRu': 'Рост объема экспорта обрабатывающей промышленности в 1,9 раза к уровню 2018 года',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 64,
        'code': '62',
        'objectCode': [
            24
        ],
        'nameRu': 'Рост объема несырьевого экспорта',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 65,
        'code': '63',
        'objectCode': [
            1,
            10,
            12
        ],
        'nameRu': 'Общая площадь застройки',
        'unit': 'квадратные метры',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 66,
        'code': '64',
        'objectCode': [
            1
        ],
        'nameRu': 'Кол-во ученических мест',
        'unit': 'человек',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 67,
        'code': '65',
        'objectCode': [
            1
        ],
        'nameRu': 'Средняя стоимость ученического места',
        'unit': 'млн тенге',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 68,
        'code': '66',
        'objectCode': [
            1
        ],
        'nameRu': 'Уровень удовлетворенности населения качеством дошкольного/среднего образования',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 69,
        'code': '67',
        'objectCode': [
            1
        ],
        'nameRu': 'Прогноз прироста детей в возрасте 6-18 лет через 3 года в НП',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'N',
        'criteria_type': 'Потребность'
    },
    {
        'id': 70,
        'code': '68',
        'objectCode': [
            10,
            12,
            15
        ],
        'nameRu': 'Уровень обеспеченности сельских населенных пунктов социальными благами и услугами в соответствии с системой региональных стандартов',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 71,
        'code': '69',
        'objectCode': [
            12,
            15
        ],
        'nameRu': 'Потребность согласно региональному стандарту',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'BR',
        'criteria_type': 'Потребность'
    },
    {
        'id': 72,
        'code': '70',
        'objectCode': [
            15
        ],
        'nameRu': 'Доступ населения к услугам водоснабжения в сельских населенных пунктах (по численности населения)',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 73,
        'code': '71',
        'objectCode': [
            15
        ],
        'nameRu': 'Качество питьевой воды (соответствует/не соответствует СЭС)',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'input',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 74,
        'code': '72',
        'objectCode': [
            15,
            16
        ],
        'nameRu': 'Доступ населения к услугам водоснабжения в городах',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 75,
        'code': '73',
        'objectCode': [
            10
        ],
        'nameRu': 'Норматив по спорт. сооружениям для ГиР',
        'unit': 'квадратные метры',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': '',
        'criteria_type': ''
    },
    {
        'id': 76,
        'code': '74',
        'objectCode': [
            10
        ],
        'nameRu': 'Средняя обеспеченность населения спортивной инфраструктурой на 1000 человек, текущее',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 77,
        'code': '75',
        'objectCode': [
            10
        ],
        'nameRu': 'Охват граждан занимающихся физической культурой и спортом',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 78,
        'code': '76',
        'objectCode': [
            10
        ],
        'nameRu': 'Уровень обеспеченности сельских населенных пунктов социальными благами и услугами в соответствии с системой региональных стандартов, текущее %**',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'input',
        'calc_type': 'R',
        'criteria_type': 'Потребность'
    },
    {
        'id': 79,
        'code': '77',
        'objectCode': [
            10
        ],
        'nameRu': 'Средняя обеспеченность населения спортивной инфраструктурой на 1000 человек',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 80,
        'code': 'kazPercent',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            32,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Казсодержание',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'form',
        'calc_type': 'N',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 81,
        'code': 'power.1',
        'objectCode': [
            1
        ],
        'nameRu': 'Снижение дефицита учен. мест',
        'unit': 'детей',
        'data_type': 'number',
        'source_type': 'form.power',
        'calc_type': 'N',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 82,
        'code': 'dateStateExamination',
        'objectCode': [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            27,
            28,
            30,
            31,
            32,
            33,
            35,
            36,
            37,
            38
        ],
        'nameRu': 'Актуальность госэкспертизы',
        'unit': 'месяцев',
        'data_type': 'number',
        'source_type': 'form',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 83,
        'code': 'EIRR',
        'objectCode': [
            29
        ],
        'nameRu': 'EIRR',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'form',
        'calc_type': 'N',
        'criteria_type': 'Бюджетные'
    },
    {
        'id': 84,
        'code': '84',
        'objectCode': [
            15
        ],
        'nameRu': 'Уровень удовлетворенности населения экологическим качеством жизни',
        'unit': 'процент',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'E',
        'criteria_type': 'Влияние на ЦИ'
    },
    {
        'id': 85,
        'code': '85',
        'objectCode': [
            22
        ],
        'nameRu': 'Наличие полигона для сортировки, хранения или утилизации ТБО',
        'unit': 'единица',
        'data_type': 'boolean',
        'source_type': 'calc',
        'calc_type': 'B',
        'criteria_type': 'Потребность'
    },
    {
        'id': 86,
        'code': '86',
        'objectCode': [
            21
        ],
        'nameRu': 'Протяженность',
        'unit': 'метр',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'N'
    },
    {
        'id': 87,
        'code': '87',
        'objectCode': [
            21
        ],
        'nameRu': 'Стоимость за 1 метр',
        'unit': 'тыс. тенге',
        'data_type': 'number',
        'source_type': 'calc',
        'calc_type': 'R',
        'criteria_type': 'Бюджетные'
    }
];


export type ProjectRealizing = BipDict;

export type ProjectAdmin = BipDict;

export type ProjectLocality = BipDict;

export type ProjectView = BipDict;

export type ProjectConsideration = BipDict;

export type ProjectGovernmentProgram = BipDict;

export class ProjectPeriod {
    get begYear(): number {
        return this._begYear;
    }

    set begYear(value: number) {
        if (value > this._endYear) {
            this._begYear = this._endYear;
        } else {
            this._begYear = value;
        }
    }

    get endYear(): number {
        return this._endYear;
    }

    set endYear(value: number) {
        if (value < this._begYear) {
            this._endYear = this._begYear;
        } else {
            this._endYear = value;
        }
    }

    private _begYear = 2021;
    private _endYear = 2021;

    static fromJSON(d: Record<string, any>): ProjectPeriod {
        return Object.assign(new ProjectPeriod(), d);
    }
}

export class ModellingData {
    get jobsCreated(): number {
        let res = 88;
        let s1 = 0;
        let s2 = 0;
        if (typeof (this.jobsCreatedPerm) === 'string') {
            s1 = parseFloat(this.jobsCreatedPerm);
        }
        if (typeof (this.jobsCreatedTemp) === 'string') {
            s2 = parseFloat(this.jobsCreatedTemp);
        }
        res = s1 + s2;
        return res;
    }

    jobsCreatedPerm = 0;
    jobsCreatedTemp = 0;
    shortageStudentPlaces = 0;
    publicResponse = 0;
    massMedia = 0;
    strikes = 0;
    complaintsState = 0;

    static fromJSON(d: Record<string, any>): ModellingData {
        return Object.assign(new ModellingData(), d);
    }
}

export class BipProjectDataClass implements IBipProjectData {
    public id = 0;
    public code: string;
    public nameRu: string;
    public nameKk: string;
    public header: any;
    public newProject?: number;
    public region_budget: string;
    public district_budget: string;
    public region: string;

    /** Дата государственной экспертизы */
    public localityObject: ILocalityObject | null;

    public period: ProjectPeriod;
    public projectType: string;
    public object: string;
    public nameCode: string;
    public check = false;

    public isDeleted = false;
    public openMore = false;

    /** Объем планируемого казахстанского содержания, в % */
    private _kazPercent: number;
    get kazPercent(): number {
        return this._kazPercent;
    }

    set kazPercent(value: number) {
        if ((value >= 0) && (value <= 100)) {
            this._kazPercent = value;
        } else {
            this._kazPercent = 100;
        }
    }

    get costError(): boolean {
        let s1 = 0;
        if (typeof (this.totalCost) === 'string') {
            s1 = parseFloat(this.totalCost);
        }
        let s2 = 0;
        if (typeof (this.costWithout) === 'string') {
            s2 = parseFloat(this.costWithout);
        }
        return s2 < s1;
    }

    get costErrorSum(): boolean {
        let s1 = 0;
        if (typeof (this.totalCost) === 'string') {
            s1 = parseFloat(this.totalCost);
        }
        let s2 = 0;
        if (typeof (this.considerationGA) === 'string') {
            s2 = parseFloat(this.considerationGA);
        }
        return s2 < s1;
    }

    private _dateStateExamination: Date | null;
    set dateStateExamination(value: Date | null) {
        if (value !== null && value > new Date()) {
            this._dateStateExamination = new Date();
        } else {
            this._dateStateExamination = value;
        }
    }

    get dateStateExamination() {
        return this._dateStateExamination;
    }

    public isDirectCash: boolean;
    public directCash: number;
    public governmentProgram: IGovProgram[] = [];
    public isConsiderationCentralGovernment: boolean;
    public considerationGA: number;
    public numStateExpertise?: string;
    public totalCost: number;
    public costWithout: number;
    public power?: number;
    public modellingData: ModellingData;

    /** Финансирование до начала планового периода */
    public oldFinace: RowOldFinanceData[] = [];

    /** Финансирование в периоде планового периода (инициатор) */
    public newFinace: RowNewFinanceData[] = [];

    /** Финансирование в периоде планового периода (получатель инвестиций) */
    public newFinaceRegional: RowNewFinanceData[] = [];

    public criteriaIndicators = new CriteriaIndicators();
    public powerIndicators = new CriteriaIndicators();
    public calcResult?: any;
    public files: any[] = [];
    public variant: string = '';
    public variant_recipient: string = '';
    public versions?: any[] = [];
    public addDatas: any = {};

    public constructor() {
        this.id = 0;
        this.code = 'BIP-00000';
        this.nameKk = 'Проектная работа';
        this.nameRu = 'Проектная работа';
        this.newProject = 1;
        this.header = {};
        this.region = '';
        this.localityObject = {} as ILocalityObject;
        this.region_budget = '';
        this.district_budget = '';
        this.object = '';
        this.projectType = '';
        this.period = new ProjectPeriod();
        this.modellingData = new ModellingData();
        this.criteriaIndicators = new CriteriaIndicators();
        this.oldFinace = [];
        this.newFinace = [];
        this.newFinaceRegional = [];
        this.files = [];
        this.isConsiderationCentralGovernment = false;
        this.isDirectCash = false;
        this._dateStateExamination = null;
        this.governmentProgram = [];
        this.numStateExpertise = '';
        this.considerationGA = 0;
        this.costWithout = 0;
        this.directCash = 0;
        this._kazPercent = 0;
        this.totalCost = 0;
        this.calcResult = {};
        this.variant = '';
        this.variant_recipient = '';
        this.nameCode = '';
    }

    static assign(d: BipProjectDataClass) {
        const data: BipProjectDataClass = Object.assign(new BipProjectDataClass(), d);
        data.period = ProjectPeriod.fromJSON(d.period);
        data.modellingData = ModellingData.fromJSON(d.modellingData);
        data.criteriaIndicators = CriteriaIndicators.fromJSON(d.criteriaIndicators);
        data.calcResult = Object.assign(Object(), d.calcResult);
        data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
        data.newFinace = RowNewFinanceData.fromJSON(d.period, d.newFinace);
        data.newFinaceRegional = RowNewFinanceData.fromJSON(d.period, d.newFinaceRegional);
        data.oldFinace = RowOldFinanceData.fromJSON(d);

        return data;
    }

    static copy(d: BipProjectDataClass) {
        const data = d;
        return data;
    }

    static transit(filter: any, d: BipProjectDataClass): BipProjectDataClass {
        const data: BipProjectDataClass = Object.assign(new BipProjectDataClass(), d);
        data.id = 0;
        data.newProject = 0;
        data.header = Object.assign({}, d.header);
        data.header.year = filter.planPeriod.year;
        data.header.dataType = filter.dataType.code;
        data.period = ProjectPeriod.fromJSON(d.period);
        data.modellingData = ModellingData.fromJSON(d.modellingData);
        data.criteriaIndicators = CriteriaIndicators.fromJSON(d.criteriaIndicators);
        data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
        data.oldFinace = RowOldFinanceData.fromJSON(data);
        data.variant = '';
        data.variant_recipient = '';
        // data.files = [];

        return data;
    }

    static fromJSON(d: Record<string, any> | BipProjectDataClass): BipProjectDataClass {
        const data: BipProjectDataClass = Object.assign(new BipProjectDataClass(), d);
        try {
            data.period = ProjectPeriod.fromJSON(d.period);
            data.modellingData = ModellingData.fromJSON(d.modellingData);
            data.criteriaIndicators = CriteriaIndicators.fromJSON(d.criteriaIndicators);
            data.calcResult = Object.assign(Object(), d.calcResult);
            data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
            data.newFinace = RowNewFinanceData.fromJSON(d.period, d.newFinace);
            if (parseInt(d.header.finalAbp) === 0 && d?.newFinaceRegional) {
                data.newFinaceRegional = RowNewFinanceData.fromJSON(d.period, d?.newFinaceRegional);
            }
            data.oldFinace = RowOldFinanceData.fromJSON(d);
        } catch (e) {
            console.log('err => ', data.id, '(e)', e.toString());
        }
        return data;
    }

    static isEqual(object1: any, object2: any): boolean {
        // const props1 = Object.getOwnPropertyNames(object1);
        // const props2 = Object.getOwnPropertyNames(object2);
        const props1 = Object.keys(object1);
        const props2 = Object.keys(object2);

        if (props1.length != props2.length) {
            console.log('notEqual', props1, props2);
            return false;
        }

        for (const prop of props2) {
            try {
                // if (prop !== '__ob__') {
                if (['header', 'regional', 'period', 'modellingData', 'criteriaIndicators',
                    'calcResult', 'powerIndicators'].includes(prop)) {
                    if (!BipProjectDataClass.isEqual(object1[prop], object2[prop])) {
                        console.log('notEqual', prop, object1[prop], object2[prop]);
                        return false;
                    }
                } else {
                    if (['gu', 'localityObject'].includes(prop)) {
                        if (object1[prop].code !== object2[prop].code) {
                            console.log('notEqual', prop, object1[prop], object2[prop]);
                            return false;
                        }
                    } else {
                        if (['oldFinace', 'newFinace', 'newFinaceRegional', 'files'].includes(prop)) {
                            if (object1[prop].length != object2[prop].length) {
                                console.log('notEqual', prop, object1[prop], object2[prop]);
                                return false;
                            } else {
                                for (let i = 0; i < object2[prop].length; i++) {
                                    for (const [key, value] of Object.entries(object2[prop][i])) {
                                        if (key === 'date'
                                            && (object1[prop][i][key].toString() === 'Invalid Date' && object2[prop][i][key].toString() === 'Invalid Date')) {
                                            continue;
                                        } else {
                                            if (object1[prop][i][key] != object2[prop][i][key]) {
                                                console.log('notEqual', key, object1[prop][i], object2[prop][i]);
                                                return false;
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            if (object1[prop] !== object2[prop]) {
                                console.log('notEqual', prop, object1[prop], object2[prop]);
                                return false;
                            }
                        }
                    }
                }
            } catch (error) {
                console.log('error_notEqual', prop, error.toString());
            }
        }
        return true;
    }
}

export class RowOldFinanceData {
    openMore = false;
    year = 2021;
    gr: number | null = null;
    pgr: number | null = null;
    abp: number | null = null;
    prg: number | null = null;
    ppr: number | null = null;
    spf: number | null = null;
    nameRu = '';
    plan = 0;
    podrad = '';
    date: Date | null = null;
    totalCoast = 0;
    smp = 0;
    control = 0;
    nadzor = 0;

    get isSumError(): boolean {
        let s1 = 0;
        let s2 = 0;
        let s3 = 0;
        let all = 0;
        if (typeof (this.smp) === 'string') {
            s1 = parseFloat(this.smp);
        }
        if (typeof (this.control) === 'string') {
            s2 = parseFloat(this.control);
        }
        if (typeof (this.nadzor) === 'string') {
            s3 = parseFloat(this.nadzor);
        }
        if (typeof (this.totalCoast) === 'string') {
            all = parseFloat(this.totalCoast);
        }
        const partSum = s1 + s2 + s3;
        return !(all < partSum);
    }

    constructor(gr: number | null, pgr: number | null, abp: number | null, prg: number | null, ppr: number | null, spf: number | null, year: number, plan: number | any) {
        this.gr = gr;
        this.pgr = pgr;
        this.abp = abp;
        this.prg = prg;
        this.ppr = ppr;
        this.spf = spf;
        this.year = year;
        this.plan = plan;
    }

    static fromJSON(d: Record<string, any>): RowOldFinanceData[] {
        const oldFinace: RowOldFinanceData[] = [];
        if (Array.isArray(d.oldFinace) && d.oldFinace.length > 0) {
            for (const row of d.oldFinace) {
                if (('prg' in row) && ('ppr' in row) && ('spf' in row)) {
                    const old: RowOldFinanceData =
                        Object.assign(new RowOldFinanceData(row?.gr, row?.pgr, row?.abp, row?.prg, row.ppr, row.spf, row.year, row.plan), row);
                    old.date = new Date(Date.parse(row.date));
                    oldFinace.push(old);
                }
            }
        } else {
            if (parseInt(d.header.finalAbp) === 1) {
                for (const obj of d.newFinace) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (parseInt(key) < parseInt(d.header?.year)) {
                            if (value) {
                                if (('prg' in obj) && ('ppr' in obj) && ('spf' in obj)) {
                                    const row = new RowOldFinanceData(obj?.gr, obj?.pgr, obj?.abp, obj?.prg, obj.ppr, obj.spf, parseInt(key), value);
                                    oldFinace.push(row);
                                }
                            }
                        }
                    }
                }
            } else {
                if (d.hasOwnProperty('newFinaceRegional')) {
                    for (const obj of d.newFinaceRegional) {
                        for (const [key, value] of Object.entries(obj)) {
                            if (parseInt(key) < parseInt(d.header?.year)) {
                                if (value) {
                                    if (('prg' in obj) && ('ppr' in obj) && ('spf' in obj)) {
                                        const row = new RowOldFinanceData(obj?.gr, obj?.pgr, obj?.abp, obj?.prg, obj.ppr, obj.spf, parseInt(key), value);
                                        oldFinace.push(row);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return oldFinace;
    }
}

export class CriteriaIndicators {
    [index: string]: number | boolean;

    static fromJSON(d: Record<string, any>): CriteriaIndicators {
        return Object.assign(new CriteriaIndicators(), d);
    }
}

export class RowNewFinanceData {
    gr: number | null = null;
    pgr: number | null = null;
    prg: number | null = null;
    ppr: number | null = null;
    spf: number | null = null;

    [index: string]: any;

    openMore = false;

    constructor(period: ProjectPeriod) {
        for (let i = period.begYear; i <= period.endYear; i++) {
            this[i] = 0;
        }
    }

    static fromJSON(period: ProjectPeriod, d: RowNewFinanceData[]): RowNewFinanceData[] {
        const arr = [];
        for (const item of d) {
            arr.push(Object.assign(new RowNewFinanceData(period), item));
        }
        return arr;
    }
}

// common methods
export function makeToast(vue: any, variant: any, title: string, tostbody: any) {
    vue.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
        appendToast: true
    });
} // сообщение

export function years(vue: any, arrYears: any[]) {
    const curYear = new Date().getFullYear();
    for (let i = curYear + 1; i >= 2022; i--) {
        const item = {year: i, name: `${i}-${i + 2}`};
        arrYears.push(item);
        if (item.year === curYear) {
            vue.planPeriod = item;
        }
    }
}

export function getRowKey(row: any, keys: any[]) {
    let key = '';
    for (const k of keys) {
        key = key + (row[k] === null ? '_.' : padLeadingZeros(row[k], 3)) + '.';
    }
    return key;
} // преобразует значения выбранных полей в код

export function doRowKey(vue: any, keys: any[]) {
    let key = '';
    for (const k of keys) {
        key = key + (k === undefined || k === null ? '_.' : padLeadingZeros(k, 3) + '.');
    }
    return key;
} // преобразует значения keys в код

export function padLeadingZeros(num: any, size: number) {
    let s = String(num);
    while (s.length < size) {
        s = '0' + s;
    }
    return s;
} // добавляет 0-ли перед num до size-значного размера

export function sortByField(field: string, sort: string = 'asc') {
    if (sort === 'asc') {
        return (a: any, b: any) => (a[field] > b[field] ? 1 : -1);
    } else {
        return (a: any, b: any) => (a[field] < b[field] ? 1 : -1);
    }
} // сортирует по наименованию поля

export function findItem(code: number | string, field: string, list: any[]) {
    for (const item of list) {
        if (item.hasOwnProperty(field) && item[field] !== null) {
            switch (typeof (code)) {
                case 'number':
                    if (parseInt(item[field]) === code) {
                        return item;
                    }
                case 'string':
                    if (item[field].toString() === code) {
                        return item;
                    }
            }
        }
    }
    return {code: null, name_ru: '', name_kz: '', name_en: ''};
} // находит элемент из заданного справочника по заданному полю

export function findItemGeneric<T extends Record<string, any>>(code: number | string, field: string, list: T[]): T | IFallbackFindItemResponse {
    for (const item of list) {
        if (item.hasOwnProperty(field) && item[field] !== null) {
            switch (typeof code) {
                case 'number':
                    if (parseInt(item[field]) === code) {
                        return item;
                    }
                    break;
                case 'string':
                    if (item[field].toString() === code) {
                        return item;
                    }
                    break;
            }
        }
    }
    return {code: null, name_ru: '', name_kz: '', name_en: ''};
}

export function getNumber(value: undefined | null | boolean | string | number, digit: number = 1): number {
    if (typeof (value) === 'undefined') {
        return 0;
    }
    if (value === null) {
        return 0;
    }
    if (typeof (value) === 'string') {
        return parseFloat(parseFloat(value).toFixed(digit));
    }
    if (typeof (value) === 'boolean') {
        return (value ? 1 : 0);
    }
    if (typeof (value) === 'number') {
        return parseFloat(value.toFixed(digit));
    }
    return 0;
} // возращает число

export function checkAccess(bip: BipProjectDataClass, operation: string, oper_recipient: string, selRegion: string) {
    const bipcode = '0';
    const regional = bip.header.hasOwnProperty('regional') && bip.header.regional;
    if (bip.addDatas.status && bip.addDatas.status.steps) {
        const operation_codes: any[] = [];
        for (const step of bip.addDatas.status.steps) {
            if (step.operation_code !== null) {
                step.operation_code.forEach((oper: string) => {
                    operation_codes.push(oper);
                });
            }
        }
        if (bip.code === bipcode) {
            console.log(bip.code, operation_codes, operation, operation_codes.includes(operation), bip.region_budget, bip.district_budget, selRegion);
        }
        // condition #1
        if ((operation_codes.length === 0) || (!operation_codes.includes(operation) && !operation_codes.includes(oper_recipient))) {
            if (bip.code === bipcode) {
                console.log('cond1', bip);
            }
            return false;
        }
        // condition #2
        if (operation_codes.includes(operation) && !operation_codes.includes(oper_recipient)
            && (bip.region_budget === selRegion)) {
            if (bip.code === bipcode) {
                console.log('cond2', bip);
            }
            return true;
        }
        // condition #3
        if (!operation_codes.includes(operation) && operation_codes.includes(oper_recipient)
            && (bip.district_budget === selRegion)) {
            if (bip.code === bipcode) {
                console.log('cond3', bip);
            }
            return true;
        }
        // condition #4
        if (operation_codes.includes(operation) && operation_codes.includes(oper_recipient)) {
            if (regional && (bip.district_budget === selRegion)) {
                if (bip.code === bipcode) {
                    console.log('cond4', bip);
                }
                return true;
            }
            if (!regional && (bip.region_budget === selRegion)) {
                if (bip.code === bipcode) {
                    console.log('cond5', bip);
                }
                return true;
            }
        }
    }
    if (bip.code === bipcode) {
        console.log('cond6', bip);
    }
    return false;
}

export function setActualYear(
    vue: any,
    versionList: any[],
    regionCode: string,
    arrYears: any[]
) {
    const versions = versionList.filter(
        (v) => v.region_code === regionCode && v.attribute
    );
    versions.sort((a, b) => {
        const aDate = new Date(a.date_start);
        const bDate = new Date(b.date_start);
        return Number(bDate) - Number(aDate);
    });

    const actualYear = versions[0].year;
    const {year, name} = arrYears.find((y) => y.year === actualYear);
    vue.planPeriod = {
        year,
        name,
    };
}

export async function findBipVariantNull(vue: any, form: BipProjectDataClass, variant: string, variant_rec: string) {
    const bip = {
        id: form.id,
        code: form.code,
        variant: variant,
        variant_recipient: variant_rec
    }
    const response =  await vue.postData('/api-py/find-bip-variant-null', bip);
    if (response > 0) {
        makeToast(vue, 'warning', 'Внимание!',
            'Сохранение ' + form.code + ' невозможно, так как Получатель не перешел на новую версию бюджета!');
        vue.findDB = response;
    }
    return response;
}

export async function findBipVariantRecNull(vue: any, form: BipProjectDataClass, variant: string, variant_rec: string) {
    const bip = {
        id: form.id,
        code: form.code,
        variant: variant,
        variant_recipient: variant_rec
    }
    const response =  await vue.postData('/api-py/find-bip-variant-rec-null', bip);
    if (response > 0) {
        makeToast(vue, 'warning', 'Внимание!',
            'Сохранение ' + form.code + ' невозможно, так как Инициатор не перешел на новую версию бюджета!');
        vue.findDB = response;
    }
    return response;
}

export function checkGu(userGus: any[], form: BipProjectDataClass) {
    if (userGus.length > 0) {
        return userGus.includes(form?.header.gu.code);
    }
    return false;
}

export function checkGuReg(userGus: any[], form: BipProjectDataClass) {
    if (userGus.length > 0) {
        return form?.header.regional && userGus.includes(form?.header.regional.gu.code);
    }
    return false;
}